import { FC } from 'react';
import IconProps from './IconProps';

const MinusIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 16 3"
      fill="currentColor"
      data-testid="icon-minus"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.3937 1.55807H1" stroke="#2B2B2B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MinusIcon;
